<template>
  <v-container>
    <v-form @submit="onSubmit" ref="addressForm">
      <v-row v-if="!readonly">
        <v-col>
          <v-switch v-model="editMode" id="editContact" :label="$t('masterdata_legal_entities_contact_edit')" color="info"></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-form :disabled="!editMode">
            <v-row>
              <v-col cols="12">
                <!-- Anrede -->
                <v-text-field
                  v-model="legalEntityAddress.salutation"
                  type="text"
                  id="search_ApplicationUser_salutation"
                  :label="$t('masterdata_legal_entities_contact_salutation')"
                />

                <!-- Name/Firma -->
                <v-text-field
                  v-model="legalEntityAddress.name1"
                  type="text"
                  id="masterdata_legal_entities_contact_name"
                  :label="$t('masterdata_legal_entities_contact_name')"
                  :rules="[rules.required]"
                />

                <!-- Vorname / Zusatz -->
                <v-text-field
                  v-model="legalEntityAddress.name2"
                  type="text"
                  id="masterdata_legal_entities_contact_fistname"
                  :label="$t('masterdata_legal_entities_contact_fistname')"
                />

                <!-- zuhanden -->
                <v-text-field
                  v-model="legalEntityAddress.attention"
                  type="text"
                  id="masterdata_legal_entities_contact_attn"
                  :label="$t('masterdata_legal_entities_contact_attn')"
                />

                <!-- Strasse -->
                <v-text-field
                  v-model="legalEntityAddress.address1"
                  type="text"
                  id="masterdata_legal_entities_contact_street"
                  :label="$t('masterdata_legal_entities_contact_street')"
                />

                <!-- Postfach -->
                <v-text-field
                  v-model="legalEntityAddress.address2"
                  type="text"
                  id="masterdata_legal_entities_contact_pobox"
                  :label="$t('masterdata_legal_entities_contact_pobox')"
                />

                <!-- PLZ -->
                <v-text-field
                  v-model.trim="legalEntityAddress.zip"
                  type="number"
                  id="zip"
                  name="zip"
                  :label="$t('masterdata_legal_entities_contact_zip')"
                  :rules="[zipRule, rules.required]"
                />

                <!-- Ort-->
                <v-select
                  v-model="legalEntityAddress.locality"
                  id="locality"
                  name="locality"
                  :items="localities"
                  item-title="localityLong"
                  item-value="localityLong"
                  :label="$t('masterdata_legal_entities_contact_location')"
                  :rules="[rules.required]"
                />

                <!-- Land -->
                <v-select
                  v-model="legalEntityAddress.country"
                  :items="countries"
                  :item-title="this.$getLangKey()"
                  item-value="name"
                  id="masterdata_legal_entities_contact_country"
                  :label="$t('masterdata_legal_entities_contact_country')"
                  :rules="[rules.required]"
                />

                <!-- Sprache-->
                <v-select
                  v-model="legalEntityAddress.languageId"
                  :items="languages"
                  id="masterdata_legal_entities_contact_language"
                  :label="$t('masterdata_legal_entities_contact_languageCode')"
                  :item-title="this.$getLangKey()"
                  item-value="id"
                  :rules="[rules.required]"
                />

                <!-- E-Mail-->
                <div class="d-flex">
                  <v-text-field
                    v-model="legalEntityAddress.email"
                    type="email"
                    id="masterdata_legal_entities_contact_email"
                    :label="$t('masterdata_legal_entities_contact_email')"
                    :rules="[rules.email]"
                  />
                  <a :href="`mailto:` + legalEntityAddress.email">
                    <v-btn icon="mdi-email" color="blue lighten-2" density="comfortable"> </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <!-- Telefon 1-->
                <div class="d-flex">
                  <v-text-field
                    v-model="legalEntityAddress.phone1"
                    type="phone"
                    id="masterdata_legal_entities_contact_phone1"
                    :label="$t('masterdata_legal_entities_contact_phone1')"
                  />
                  <a :href="`tel:` + $formatPhone(legalEntityAddress.phone1)">
                    <v-btn class="ma-0 action-btn" icon="mdi-phone" color="blue lighten-2" density="comfortable"> </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <!-- Hinweis Telefon 1-->
                <v-text-field
                  v-model="legalEntityAddress.phone1Hint"
                  type="text"
                  id="masterdata_legal_entities_contact_phone1Hint"
                  :label="$t('masterdata_legal_entities_contact_phone1Hint')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <!-- Telefon 2-->
                <div class="d-flex">
                  <v-text-field
                    v-model="legalEntityAddress.phone2"
                    type="phone"
                    id="masterdata_legal_entities_contact_phone2"
                    :label="$t('masterdata_legal_entities_contact_phone2')"
                  />
                  <a :href="`tel:` + $formatPhone(legalEntityAddress.phone2)">
                    <v-btn class="ma-0 action-btn" icon="mdi-phone" color="blue lighten-2" density="comfortable"> </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <!-- Hinweis Telefon 2-->
                <v-text-field
                  v-model="legalEntityAddress.phone2Hint"
                  type="text"
                  id="masterdata_legal_entities_contact_phone2Hint"
                  :label="$t('masterdata_legal_entities_contact_phone2Hint')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <!-- Telefon 3-->
                <div class="d-flex">
                  <v-text-field
                    v-model="legalEntityAddress.phone3"
                    type="phone"
                    id="masterdata_legal_entities_contact_phone3"
                    :label="$t('masterdata_legal_entities_contact_phone3')"
                  />
                  <a :href="`tel:` + $formatPhone(legalEntityAddress.phone3)">
                    <v-btn class="ma-0 action-btn" icon="mdi-phone" color="blue lighten-2" density="comfortable"> </v-btn>
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <!-- Hinweis Telefon 3-->
                <v-text-field
                  v-model="legalEntityAddress.phone3Hint"
                  type="text"
                  id="masterdata_legal_entities_contact_phone3Hint"
                  :label="$t('masterdata_legal_entities_contact_phone3Hint')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col cols="12" md="6">
          <!-- Addressvorschau -->
          <address-preview v-bind:address="legalEntityAddress"></address-preview>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        <v-btn id="btn-submit" :disabled="!editMode" v-if="!readonly" color="primary" class="button-margin button-top-margin shadow-none" @click="onSubmit()">
          <span v-html="$t('masterdata_legal_entities_overview_save')" />
        </v-btn>
        <v-btn
          id="btn-submit-close"
          :disabled="!editMode"
          v-if="!readonly"
          color="secondary"
          class="button-margin button-top-margin shadow-none"
          @click="submitAndClose()"
        >
          <span v-html="$t('masterdata_legal_entities_overview_save_and_close')" />
        </v-btn>
        <v-btn id="btn-edit-cancel" color="secondary" class="button-margin button-top-margin shadow-none" @click="navigateBackToSearch()"
          ><span v-html="$t('legal_entities_back_button')"
        /></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import addressPreview from '@/views/masterdata/addressPreview.vue'
import { BaseAddress } from '@/views/contacts/contact'
import { useLanguagesStore } from '@/store/enums/LanguagesStore'
import _ from 'lodash'
import { privileges } from '@/services/privileges'
import { useCountriesStore } from '@/store/enums/CountriesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'legalEntitiesAddresses',
  components: { addressPreview },
  props: ['defaultAddressId'],
  data() {
    return {
      legalEntityAddress: new BaseAddress(),
      editMode: false,
      fieldDef: {
        zip: {
          min: 4,
          max: 4
        }
      },
      localities: [],
      rules: {
        required: (value) => !!value || this.$t('form_field_required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || this.$t('form_field_invalid_email')
        }
      }
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/legalEntities', permission: 'write' })
    },
    countries() {
      return useCountriesStore().Countries
    },
    languages() {
      useLanguagesStore().languages
    }
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.addressForm.validate()).valid) {
        return false
      }

      try {
        const result = await this.axios.patch(
          apiURL + '/legalEntityAddresses/' + this.legalEntityAddress.id,
          {
            ..._.omit(this.legalEntityAddress, ['formattedAddress', 'canton', 'languageId']),
            ...{ language: { id: this.legalEntityAddress.languageId } },
            locality: this.legalEntityAddress.locality,
            zip: this.legalEntityAddress.zip
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('masterdata_legal_entity_edit_toast_success')
          })
          this.$refs.history?.loadHistory()
        }
      } catch (e) {
        showError(e)
      }
    },
    zipRule() {
      if (typeof parseInt(this.legalEntityAddress.zip) != 'number') {
        return false
      }
      //im schweizer range
      if (this.legalEntityAddress.zip < 999 || this.legalEntityAddress.zip > 9999) {
        return false
      }
      //für die Plz konnte ein Ort aufgelöst werden
      return this.localities?.length > 0
    },
    submitAndClose() {
      this.onSubmit()
      this.navigateBackToSearch()
    },
    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_legalEntities_search'
        })
        .catch(() => {
          /* duplicated route */
        })
    },
    async loadLocalities(zip) {
      const localityByZip = await this.axios.get(apiURL + '/postcodes/findByZip', { params: { zip: zip } }) //immer alle
      this.localities = localityByZip.data.content

      if (this.localities.length === 1) {
        //chose when no choice
        this.legalEntityAddress.locality = this.localities[0].localityLong
      } else if (this.localities.findIndex((l) => l.localityLong === this.legalEntityAddress.locality) > 0) {
        // do nothing
      } else {
        // force the user to select locality
        this.legalEntityAddress.locality = null
      }
    }
  },
  watch: {
    'legalEntityAddress.zip': async function (newVal) {
      if (newVal >= 1000) {
        await this.loadLocalities(newVal)
      }
    }
  },
  async mounted() {
    useCountriesStore().fetchCountries()
    try {
      const response = await this.axios.get(apiURL + '/legalEntities/' + this.$route.params.id + '' + '/defaultAddress', {
        headers: { Accept: 'application/json' }
      })
      this.legalEntityAddress = response.data as BaseAddress
      this.legalEntityAddress.languageId = response.data.language.id
      this.loadLocalities(this.legalEntityAddress.zip)
    } catch (e) {
      this.legalEntityAddress = []
      showError(e)
    }
  }
})
</script>
