<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="12" class="bottom-margin">
        <div>
          <h2 v-html="$t('masterdata_legalEntities_bewirtschaftet_title')"></h2>
          <v-row>
            <v-col>
              <div v-if="bewirtschafter && bewirtschafter.length > 0">
                <!-- existing relations -->
                <v-data-table-server
                  density="compact"
                  :headers="fields"
                  :items="bewirtschafter"
                  :loading="loading"
                  :hide-default-footer="true"
                  disable-pagination
                  class="custom-simple-table"
                >
                  <template v-slot:[`item.validFrom`]="{ item }">
                    <dbmDatePicker
                      class="nomessage"
                      v-model="item.validFrom"
                      :required="true"
                      @change="
                        (emittedDate) => {
                          item.validFrom = emittedDate
                          patchRow(item)
                        }
                      "
                    ></dbmDatePicker>
                  </template>
                  <template v-slot:[`item.validUntil`]="{ item }">
                    <dbmDatePicker
                      class="nomessage"
                      v-model="item.validUntil"
                      :clearable="true"
                      @change="
                        (emittedDate) => {
                          item.validUntil = emittedDate
                          patchRow(item)
                        }
                      "
                    ></dbmDatePicker>
                  </template>
                  <template
                    v-slot:[`item.delete`]="{ item }"
                    v-if="
                      $privileges.has({
                        path: '/managements',
                        permission: 'delete'
                      })
                    "
                  >
                    <v-btn :loading="deleteManagementLoading" icon="mdi-delete" @click="deleteManagement(item)" density="comfortable">
                      <v-icon dbmblueprimary>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table-server>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn id="addRelation-submit-btn" color="primary" @click="addRelation()"><span v-html="$t('masterdata_legal_entities_add')" /></v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <v-btn id="btn-edit-cancel" color="secondary" class="button-margin button-top-margin shadow-none" @click="navigateBackToSearch()"
            ><span v-html="$t('legal_entities_back_button')"
          /></v-btn>
        </div>
      </v-col>
    </v-row>

    <!--            Search/Select Farm / Dairy -->
    <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="masterdata-legalEntities-dialog">
      <v-card>
        <v-card-title><span v-html="$t('masterdata_legalEntities_add_new_relation')" /></v-card-title>
        <v-card-text>
          <v-layout row justify-center>
            <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                </v-layout>
              </v-container>
            </v-overlay>
          </v-layout>
          <v-row class="row search spacer-md">
            <v-col cols="6" md="6" xl="4">
              <!--                    Local Unit Picker -->
              <dbm-extended-local-unit-picker
                v-model="selected.localUnitId"
                :label="$t('masterdata_legalEntity_relation_picker')"
                :cards="['searchLocalUnit', 'searchFarm', 'searchContact']"
                :rules="[$rules.required]"
              />
              <!-- ValidFrom -->
              <DbmDatePicker v-model="selected.validFrom" :dateLabel="$t('masterdata_legalEntities_valid_from')" :clearable="true" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn id="submit-btn" class="m-2" color="primary" @click="submit(id)" type="submit"
            ><span v-html="$t('masterdata_legalEntities_add_button')"
          /></v-btn>
          <v-btn id="close-btn" class="m-2" color="secondary" @click="closeDialog()" type="submit"
            ><span v-html="$t('masterdata_legalEntities_close')"
          /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
import DbmExtendedLocalUnitPicker from '@/components/pickers/dbmExtendedLocalUnitPicker.vue'
export default defineComponent({
  name: 'legalEntitiesRelations',
  props: {
    legalEntityId: { type: [Number, String], default: '' } // this.$route.params.id is a string
  },
  data() {
    return {
      loading: false,
      bewirtschafter: [],
      items: [],
      selected: { validFrom: null, localUnitId: null },
      dialogsettings: { loading: false, totalElements: 0 },
      deleteManagementLoading: false,
      fields: <DTSHeader[]>[
        {
          title: this.$t('masterdata_legalEntities_farmTypeName'),
          key: 'typeName',
          sortable: false
        },
        {
          title: this.$t('masterdata_legalEntities_localUnitBerNo'),
          key: 'localUnitBerNo',
          sortable: false
        },
        {
          title: this.$t('affiliations_valid_from'),
          key: 'validFrom',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('affiliations_valid_until'),
          key: 'validUntil',
          sortable: false,
          width: '25%'
        },
        // Delete icon
        {
          title: '',
          key: 'delete',
          sortable: false
        }
      ],
      managementId: 0,
      dialog: false
    }
  },
  components: {
    DbmExtendedLocalUnitPicker,
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    async deleteManagement(item) {
      const formatter = new CustomFormatter()
      const formattedMessage = formatter.interpolate(this.$t('masterdata_legalEntities_managements_confirm_delete'), [item.localUnitBerNo])
      if (!confirm(formattedMessage)) return

      this.deleteManagementLoading = true
      try {
        const result = await this.axios.delete(
          apiURL + '/managements/' + item.managementId,
          { params: { id: item.managementId } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.bewirtschafter = await this.loadRelations(this.$route.params.id)
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteManagementLoading = false
      }
    },
    async loadRelations(itemId) {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/legalEntities/' + itemId + '/managements', { headers: { Accept: 'application/json' } })
        const items = await response.data
        for (let item of items) {
          item.typeName = item[`type${this.$getUpLangKey()}`]
        }
        return Array.isArray(items) ? items : []
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async patchRow(item) {
      this.loading = true
      try {
        const result = this.axios.patch(`/api/managements/${item.managementId}`, {
          validFrom: item.validFrom ? item.validFrom : null,
          validUntil: item.validUntil ? item.validUntil : null
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          return result.data
        }
      } catch (e) {
        item.validFrom = ''
        item.validUntil = ''
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async submit() {
      this.loading = true
      try {
        const result = await this.axios.post(
          apiURL + '/managements/',
          {
            validFrom: this.selected.validFrom,
            legalEntity: { id: this.legalEntityId },
            localUnit: { id: this.selected.localUnitId }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.bewirtschafter = await this.loadRelations(this.$route.params.id)
          this.dialog = false
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    addRelation() {
      this.dialog = true
      this.loading = false
    },
    closeDialog() {
      this.dialog = false
      this.selected = { validFrom: null, localUnitId: null }
    },

    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_legalEntities_search'
        })
        .catch(() => {
          /* duplicated route */
        })
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.bewirtschafter = await this.loadRelations(this.$route.params.id)
    })
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.add-bewirtschafter {
  margin-bottom: 0;
  color: #00a1db;
}

.add-bewirtschafter:hover {
  cursor: pointer;
}

.v-data-table-server {
  margin-top: 20px;
}

.bottom-margin {
  margin-bottom: $spacer-sm;
}
</style>
