<template>
  <div class="container" id="legalEntitiesOverview">
    <h1 :class="this.isActive ? '' : 'inactive-cell'">
      {{ this.legalEntity['title' + $route.params.upLang] }}
    </h1>

    <v-tabs v-model="activeTab" color="primary">
      <v-tab id="overview" value="overview"><span v-html="$t('masterdata_legal_entities_overview_general_data')" /></v-tab>
      <v-tab id="addresses" value="addresses"><span v-html="$t('masterdata_legal_entities_overview_addresses')" /></v-tab>
      <v-tab id="relations" value="relations" v-if="!readonly"><span v-html="$t('masterdata_legal_entities_overview_relations')" /></v-tab>
      <v-tab id="datasource" value="datasource" v-if="!readonly"><span v-html="$t('masterdata_legal_entities_overview_data_source')" /></v-tab>
    </v-tabs>
    <v-window v-model="activeTab">
      <v-window-item value="overview">
        <legal-entities-overview v-if="childDataLoaded" :legalEntityData="this.legalEntity"></legal-entities-overview>
      </v-window-item>
      <v-window-item value="addresses">
        <legal-entities-addresses></legal-entities-addresses>
      </v-window-item>
      <v-window-item value="relations" v-if="!readonly">
        <legal-entities-relations :legalEntityId="id"></legal-entities-relations>
      </v-window-item>
      <v-window-item value="datasource" v-if="!readonly">
        <legal-entities-datasource></legal-entities-datasource>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import legalEntitiesDatasource from './datasource'
import legalEntitiesRelations from './relations'
import legalEntitiesAddresses from './addresses'
import legalEntitiesOverview from './overview'
import _ from 'lodash'
import { privileges } from '@/services/privileges'
import { apiURL } from '@/main'
import { showError } from '@/services/axios'

let today = new Date()
let yesterday = moment(today).subtract(1, 'day').toISOString()

import { defineComponent } from 'vue'
import moment from 'moment'
export default defineComponent({
  name: 'legalEntitiesEdit',
  components: {
    legalEntitiesDatasource,
    legalEntitiesRelations,
    legalEntitiesAddresses,
    legalEntitiesOverview
  },
  props: { id: { Number }, tab: { String } },
  data() {
    return {
      apiURL: apiURL + '/legalEntities/',
      formPart: {
        id: null
      },
      legalEntity: {},
      childDataLoaded: false,
      isActive: true
    }
  },
  computed: {
    activeTab: {
      get(): String {
        return this.tab
      },
      set(tabChoosen: string): void {
        console.log('tabChoosen', tabChoosen)
        this.$router
          .push({
            name: 'masterdata_legal_entities_edit',
            params: { id: parseInt(this.id), tab: tabChoosen }
          })
          .catch((e) => {
            /* nav duplicated */
          })
      }
    },
    readonly() {
      return !privileges.has({ path: '/legalEntities', permission: 'write' })
    }
  },
  methods: {
    async loadLegalEntity(legalEntityId) {
      try {
        const response = this.axios.get((this.apiURL += legalEntityId), {
          headers: { Accept: 'application/json' }
        })
        this.legalEntity = (await response).data
        this.childDataLoaded = await response
        if ((await response).data.validUntil <= yesterday) {
          this.isActive = false
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  watch: {},
  mounted() {
    this.loadLegalEntity(this.$route.params.id)
  }
})
</script>
