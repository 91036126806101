<template>
  <v-container>
    <h1>tbd</h1>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'legalEntitiesDatasource',
  data() {
    return {}
  }
})
</script>
