<template>
  <v-container>
    <v-form @submit="onSubmit">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <!-- LegalEntityType -->
          <v-text-field
            disabled
            v-model="legalEntity['legalEntityType' + this.$getUpLangKey()]"
            id="legalEntityType"
            item-text="text"
            :label="$t('masterdata_legal_entities_overview_legal_entity_form')"
          />
          <!-- UID -->
          <v-text-field
            :disabled="readonly"
            v-model="legalEntity.uid"
            type="string"
            id="uid"
            item-text="text"
            :label="$t('masterdata_legal_entities_overview_uid')"
            :append-outer-icon="legalEntity.uid ? 'mdi-open-in-new' : ''"
            @click:append-outer="gotoUID"
          />
          <!-- UID Status -->
          <v-select
            :disabled="readonly"
            v-model="legalEntity.uidStatus"
            id="uidStatus"
            :items="booleanOptions"
            :label="$t('masterdata_legal_entities_overview_uid_status')"
            item-title="text"
            item-value="status"
          />
          <!-- Rechtsform -->
          <v-select
            :disabled="readonly"
            id="legalForm"
            :items="legalForms"
            :label="$t('masterdata_legal_entities_overview_legal_form')"
            :item-title="this.$getLangKey()"
            item-value="id"
            v-model="legalEntity.legalFormId"
          />
          <!-- EGID -->
          <v-text-field :disabled="readonly" v-model="legalEntity.egid" type="number" id="egid" :label="$t('masterdata_legal_entities_overview_egid')" />
          <!-- AGIS-ID Person -->
          <v-text-field
            :disabled="readonly"
            v-model="legalEntity.agisId"
            type="number"
            id="agisIdPerson"
            :label="$t('masterdata_legal_entities_overview_agis_id_person')"
          />
          <!-- Inaktiv per - valid until-->
          <DbmDatePicker
            :readonly="readonly"
            v-model="legalEntity.validUntil"
            @update:model-value="
              (arg) => {
                console.log('modelValue', arg)
              }
            "
            :dateLabel="$t('masterdata_inactive_by')"
            :clearable="true"
          />
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <DisplaySubsidyCreditor backendApi="legalEntities" :id="this.$route.params.id" creditorType="production" />
        </v-col>
        <v-col cols="12" v-if="$getBeta()" class="beta">
          <h2 v-html="$t('masterdata_farms_hierarchy')"></h2>
          <hierarchy type="LegalEntity" :id="$route.params.id"></hierarchy>
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6" lg="6">
          <rdhComponent
            :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
            :remarks_show="true"
            :remarks_tag="'legal_entities_' + $route.params.id"
            :documents_show="true"
            :documents_tag="'legal_entities_' + $route.params.id"
            :history_show="true"
            :history_url="'/legalEntities/' + $route.params.id"
            ref="rdh"
          ></rdhComponent>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col sm="12">
        <v-btn id="btn-edit-save" color="primary" class="shadow-none" @click="onSubmit()" v-if="!readonly"
          ><span v-html="$t('masterdata_legal_entities_overview_save')"
        /></v-btn>
        <v-btn id="btn-edit-save-and-cancel" color="secondary" class="shadow-none" @click="submitAndClose()" v-if="!readonly"
          ><span v-html="$t('masterdata_legal_entities_overview_save_and_close')"
        /></v-btn>
        <v-btn id="btn-edit-cancel" color="secondary" class="button-margin button-top-margin shadow-none" @click="navigateBackToSearch()"
          ><span v-html="$t('legal_entities_back_button')"
        /></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import _ from 'lodash'
import DbmDatePicker from '../../components/dbmDatePicker.vue'
import DisplaySubsidyCreditor from '@/components/displayCards/displaySubsidyCreditor.vue'
import rdhComponent from '@/components/rdh/rdhComponent'
import Hierarchy from '@/components/hierarchy.vue'

import { privileges } from '@/services/privileges'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'legalEntitiesOverview',
  props: { legalEntityData: { type: Object, required: true } },
  components: {
    DbmDatePicker: DbmDatePicker,
    DisplaySubsidyCreditor: DisplaySubsidyCreditor,
    rdhComponent: rdhComponent,
    hierarchy: Hierarchy
  },
  data() {
    return {
      legalEntity: {},
      legalEntityDefaultContact: {},
      legalForms: [],
      booleanOptions: [
        {
          id: 0,
          status: false,
          text: this.$t('masterdata_new_legal_entity_false')
        },
        {
          id: 1,
          status: true,
          text: this.$t('masterdata_new_legal_entity_true')
        }
      ],
      defaultAddressId: 3 // test
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/legalEntities', permission: 'write' })
    }
  },
  methods: {
    gotoUID() {
      window.open('https://www.uid.admin.ch/Detail.aspx?uid_id=' + this.legalEntity.uid, '_blank')
    },
    async getLegalForms() {
      const result = await this.axios.get(apiURL + '/legalForms', {})
      this.legalForms = await result.data
    },
    async onSubmit() {
      let legalEntityLegalFormId = this.legalEntity.legalFormId
      let legalEntityTypeId = this.legalEntity.legalEntityTypeId

      try {
        const result = await this.axios.patch(
          apiURL + '/legalEntities/' + this.$route.params.id,
          {
            ..._.omit(this.legalEntity, [
              'legalFormCode',
              'legalFormId',
              'legalEntityTypeId',
              'legalFormNameDe',
              'legalFormNameFr',
              'legalFormNameIt',
              'legalEntityTypeNameDe',
              'legalEntityTypeNameFr',
              'legalEntityTypeNameIt',
              'legalEntityTypeCode'
            ]),
            validUntil: this.$formatDateTime(this.legalEntity.validUntil),
            legalForm: { id: legalEntityLegalFormId },
            legalEntityType: { id: legalEntityTypeId }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('masterdata_legal_entity_edit_toast_success')
          })
          this.legalEntity.legalFormId = legalEntityLegalFormId
          this.legalEntity.legalEntityTypeId = legalEntityTypeId
          emitter.emit('loadHistory')
        }
      } catch (e) {
        showError(e)
      }
    },
    submitAndClose() {
      this.onSubmit()
      this.navigateBackToSearch()
    },
    navigateBackToSearch() {
      this.$router
        .push({
          name: 'masterdata_legalEntities_search'
        })
        .catch(() => {
          /* duplicated route */
        })
    }
  },
  mounted() {
    this.getLegalForms()
    this.legalEntity = this.legalEntityData
  }
})
</script>
